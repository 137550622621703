import { Contract } from '@ethersproject/contracts'
import { ethers } from 'ethers'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
// import { JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@lcswap/sdk'
// import IUniswapV2Router02ABI from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
// import IUniswapV2Router02ABI from '../config/abi/IUniswapV2Router02.json'
// import IUniswapV2PairABI from '../config/abi/IUniswapV2Pair.json'
// import Erc20ABI from 'config/abi/erc20.json'
// import GardenABI from '../config/abi/MasterGardener.json'
// import LockABI from '../config/abi/LockToken.json'
// import FactoryABI from '../config/abi/Factory.json'
// import { ROUTER_ADDRESS, GARDEN_ADDRESS, LOCK_ADDRESS } from '../config/constants'
import { BASE_ETHER_SCAN_URLS } from 'config'
// import { TokenAddressMap } from '../state/lists/hooks'
import { simpleRpcProvider } from './providers'

export enum ChainId {
  MAINNET = 1,
  TESTNET = 3
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getEtherScanLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainId: ChainId = ChainId.MAINNET,
): string {
  switch (type) {
    case 'transaction': {
      return `${BASE_ETHER_SCAN_URLS[chainId]}/tx/${data}`
    }
    case 'token': {
      return `${BASE_ETHER_SCAN_URLS[chainId]}/token/${data}`
    }
    case 'block': {
      return `${BASE_ETHER_SCAN_URLS[chainId]}/block/${data}`
    }
    case 'countdown': {
      return `${BASE_ETHER_SCAN_URLS[chainId]}/block/countdown/${data}`
    }
    default: {
      return `${BASE_ETHER_SCAN_URLS[chainId]}/address/${data}`
    }
  }
}

export function getBscScanLinkForNft(
  collectionAddress: string,
  tokenId: string,
  chainId: ChainId = ChainId.MAINNET,
): string {
  return `${BASE_ETHER_SCAN_URLS[chainId]}/token/${collectionAddress}?a=${tokenId}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
// export function basisPointsToPercent(num: number): Percent {
//   return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
// }

// export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
//   if (slippage < 0 || slippage > 10000) {
//     throw Error(`Unexpected slippage value: ${slippage}`)
//   }
//   return [
//     JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
//     JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
//   ]
// }

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, signer?: ethers.Signer | ethers.providers.Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer ?? simpleRpcProvider)
}

// account is optional
// export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
//   return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI.abi, getProviderOrSigner(library, account))
// }

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

// export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
//   if (currency === ETHER) return true
//   return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
// }

// export function getGardenContract(_: number, library: Web3Provider, account?: string): Contract {
//   return getContract(GARDEN_ADDRESS, GardenABI, getProviderOrSigner(library, account))
// }

// export function getLockTokenContract(_: number, library: Web3Provider, account?: string): Contract {
//   return getContract(LOCK_ADDRESS, LockABI, getProviderOrSigner(library, account))
// }

// export function getPairContract(address: string): Contract {
//   return getContract(address, IUniswapV2PairABI)
// }


// export function getErc20Contract(address: string): Contract {
//   return getContract(address,Erc20ABI)
// }

// export function getFactoryContract(factoryAddress: string) {
//   return getContract(factoryAddress, FactoryABI)
// }
