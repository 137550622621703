import { useMemo } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { Contract } from '@ethersproject/contracts'
import { getContract, getProviderOrSigner } from '../utils'
import ERC20_ABI from '../config/abi/erc20.json'
import { getBorrowerContract, getErc721Contract, getLenderContract, getMQueryContract } from 'utils/contractHelpers';

// // returns null on errors
function useContract<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): T | null {
  const { library, account }:any = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, withSignerIfPossible ? getProviderOrSigner(library, account) : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export const useERC721 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getErc721Contract(address, library.getSigner()), [address, library])
}

export const useMQueryContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getMQueryContract(library.getSigner()), [library])
}

export const useBorrowerContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBorrowerContract(library.getSigner()), [library])
}

export const useLenderContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getLenderContract(library.getSigner()), [library])
}
