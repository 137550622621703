import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@moma-nft/momanft-ui'
import { useWeb3React } from '@web3-react/core'
// import tokens from 'config/constants/tokens'
import { FetchStatus } from 'config/constants/types'
import useAuth from 'hooks/useAuth'
import useTokenBalance, { useGetBnbBalance, useGetETHBalance } from 'hooks/useTokenBalance'
import React from 'react'
import { getEtherScanLink } from 'utils'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'

interface WalletInfoProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowBnbBalance, onDismiss }) => {
  const { account }:any = useWeb3React()
  // const { balance, fetchStatus } = useGetBnbBalance()
  const { balance, fetchStatus } = useGetETHBalance()
  // const { balance: lcBalance, fetchStatus: lcFetchStatus } = useTokenBalance('tokens.lc.address')
  const { logout } = useAuth()

  const handleLogout = () => {
    if (onDismiss) {
      onDismiss()
    }
    logout()
  }

  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        Your Address
      </Text>
      <CopyAddress account={account} mb="24px" />
      {/* {hasLowBnbBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold">BNB Balance Low</Text>
            <Text as="p">You need BNB for transaction fees.</Text>
          </Box>
        </Message>
      )} */}
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="textSubtle">ETH Balance</Text>
        {/* {fetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{formatBigNumber(balance, 6)}</Text>
        )} */}
        <Text>{formatBigNumber(balance, 6)}</Text>
      </Flex>
      {/* <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Text color="textSubtle">LC Balance</Text>
        {lcFetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{getFullDisplayBalance(lcBalance, 18, 3)}</Text>
        )}
      </Flex> */}
      <Flex alignItems="center" justifyContent="end" mb="24px">
        <LinkExternal href={getEtherScanLink(account, 'address')}>View on EtherScan</LinkExternal>
      </Flex>
      <Button variant="secondary" width="100%" onClick={handleLogout}>
        Disconnect Wallet
      </Button>
    </>
  )
}

export default WalletInfo
