import React, {lazy} from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import Normalize from './style/Normalize';
import GlobalStyle from './style/Global';
import history from './routerHistory';
import PageLoader from './components/Loader/PageLoader'
import Footer from './components/Footer';
import Menu from './components/Menu';
import { SuspenseWithChunkError } from './components/SuspenseWithChunkError';
import { ToastListener } from 'contexts/ToastsContext';
import useEagerConnect from './hooks/useEagerConnect'

const Markets = lazy(() => import('./views/Markets'))
const Dashboard = lazy(() => import('./views/Dashboard'))
const LoanDetails = lazy(() => import('./views/LoanDetails'))
const MyNFTs = lazy(() => import('./views/MyNFTs'))
const MyLiquidity = lazy(() => import('./views/MyLiquidity'))

const App:React.FC = () => {
  useEagerConnect()
  return (
    <Router history={history}>
      <Normalize/>
      <GlobalStyle/>
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            {/* 路由重定向home到首页 */}
            <Redirect path="/" exact to="/dashboard" />
            <Route path="/markets" component={Markets} />
            <Route exact strict path="/dashboard" component={Dashboard} />
            <Route exact strict path="/loanDetails/:address/:id" component={LoanDetails} />
            <Route exact strict path="/myNFTs" component={MyNFTs} />
            <Route exact strict path="/myLiquidity" component={MyLiquidity} />
            {/* <Route path="/">
              <Home/>
            </Route> */}
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
      <Footer/>
    </Router>
  );
}

export default App;
