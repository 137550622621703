import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
// import tokens from 'config/constants/tokens'
import { FAST_INTERVAL, SLOW_INTERVAL } from '../contexts/RefreshContext'
import { ethers } from 'ethers'
import useSWR from 'swr'
import { BIG_ZERO } from '../utils/bigNumber'
import { simpleRpcProvider } from '../utils/providers'
import { useTokenContract } from './useContract'
import { useSWRContract } from './useSWRContract'

const useTokenBalance = (tokenAddress: string) => {
  const { account } = useWeb3React()

  const contract = useTokenContract(tokenAddress, false)
  const { data, status, ...rest } = useSWRContract(
    account
      ? {
          contract,
          methodName: 'balanceOf',
          params: [account],
        }
      : null,
    {
      refreshInterval: FAST_INTERVAL,
    },
  )// FAST_INTERVAL SLOW_INTERVAL

  return {
    ...rest,
    fetchStatus: status,
    balance: data ? new BigNumber(data.toString()) : BIG_ZERO,
  }
}

export const useGetBnbBalance = () => {
  const { account }:any = useWeb3React()
  const { status, data, mutate } = useSWR([account, 'bnbBalance'], async () => {
    return simpleRpcProvider.getBalance(account)
  })

  return { balance: data || ethers.constants.Zero, fetchStatus: status, refresh: mutate }
}

export const useGetETHBalance = () => {
  const { account }:any = useWeb3React()
  const { status, data, mutate } = useSWR([account], async () => {
    return simpleRpcProvider.getBalance(account)
  })

  // const newData = new BigNumber(data._hex).div(10**18).toString()

  console.log(account)
  console.log(status)
  // console.log(newData)

  return { balance: data || ethers.constants.Zero, fetchStatus: status, refresh: mutate }
}

export default useTokenBalance
