import { ethers } from 'ethers'
import { simpleRpcProvider } from './providers'
import MQuertABI from '../config/abi/moma-nft-mquery.json'
import BorrowerABI from '../config/abi/moma-nft-borrower.json'
import LenderABI from '../config/abi/moma-nft-lender.json'
import erc721Abi from '../config/abi/erc721.json'

import {
  getMQueryAddress,
  getBorrowerAddress,
  getLenderAddress
} from 'utils/addressHelpers'

import {
  MomaNftMquery,
  MomaNftBorrower,
  MomaNftLender,
  Erc721
} from 'config/abi/types'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer) as Erc721
}

export const getMQueryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MQuertABI, getMQueryAddress(), signer) as MomaNftMquery
}

export const getBorrowerContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(BorrowerABI, getBorrowerAddress(), signer) as MomaNftBorrower
}

export const getLenderContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(LenderABI, getLenderAddress(), signer) as MomaNftLender
}

