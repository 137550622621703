import React from 'react'
import { useLocation } from 'react-router'
import { Menu as UikitMenu } from '@moma-nft/momanft-ui'
// import { languageList } from 'config/localization/languages'
// import { useTranslation } from 'contexts/Localization'
// import PhishingWarningBanner from 'components/PhishingWarningBanner'
// import useTheme from 'hooks/useTheme'
import { Link } from 'react-router-dom'
// import { usePhishingBannerManager } from 'state/user/hooks'
import config from './config/config'
import UserMenu from './UserMenu'
// import GlobalSettings from './GlobalSettings'
// import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

// import { footerLinks } from './config/footerConfig'
// import useMenuLockBalance, { useMenuTotalBalance } from 'hooks/useMenuBalance'
// import milliFormat from 'utils/formatMilil'
// import { getFullDisplayBalance } from 'utils/formatBalance'

const Menu = (props:any) => {
  // const { isDark, toggleTheme } = useTheme()
  // const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()
  // const [showPhishingWarningBanner] = usePhishingBannerManager()

  // const activeMenuItem = getActiveMenuItem({ menuConfig: config(), pathname })
  // const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  // Locked Balance
  // const { balance: lockNum} = useMenuLockBalance()
  // console.log(" - - Menu lock balance - - ")
  // console.log(lockNum)

  // Total Balance
  // const { balance: totalNum} = useMenuTotalBalance()
  // console.log(" - - Menu total balance - - ")
  // console.log(totalNum)

  return (
    <UikitMenu
      linkComponent={({ href, ...linkProps }: any) => {
        return <Link to={href} {...linkProps} />
      }}
      userMenu={<UserMenu />}
      // globalMenu={<GlobalSettings />}
      // banner={showPhishingWarningBanner && <PhishingWarningBanner />}
      // isDark={isDark}
      // toggleTheme={toggleTheme}
      // currentLang={currentLanguage.code}
      // langs={languageList}
      // setLang={setLanguage}
      // cakePriceUsd={cakePriceUsd.toNumber()}
      links={config()}
      // subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
      // footerLinks={footerLinks(t)}
      // activeItem={activeMenuItem?.href}
      // activeSubItem={activeSubMenuItem?.href}
      // buyCakeLabel={t('Buy LC')}
      // lockedTitle={t('Locked ')}
      // lockedBalance={milliFormat(getFullDisplayBalance(lockNum, 18, 4))}
      // totalTitle={t('Total ')}
      // totalBalance={milliFormat(getFullDisplayBalance(totalNum, 18, 4))}
      {...props}
    />
  )
}

export default Menu
