
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[1]
}

export const getMQueryAddress = () => {
  return getAddress(addresses.MQuery)
}
export const getBorrowerAddress = () => {
  return getAddress(addresses.BorrowerMaster)
}
export const getLenderAddress = () => {
  return getAddress(addresses.LenderMasterEther)
}
