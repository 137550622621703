import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { BIG_TEN } from './bigNumber'

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).times(BIG_TEN.pow(decimals))
}

export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals))
}

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  return getBalanceAmount(balance, decimals).toNumber()
}

export const getConvertPercent = (balance: any, decimals = 18, displayDecimals: number = 2) => {
  return formatNumberPoint(getBalanceNumber(new BigNumber(balance.toString()), decimals) * 100, displayDecimals)
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, displayDecimals: number) => {
  // return getBalanceAmount(balance, decimals).toFixed(displayDecimals)
  return getBalanceAmount(balance, decimals).toFixed(displayDecimals, 1)
}

export const showFullDisplayBalance = (balance: any, displayDecimals: number = 2) => {
  return showFullDisplay(balance, 18, displayDecimals)
}

export const showFullDisplay = (balance: any, decimals = 18, displayDecimals: number = 2) => {
  return formatNumber(getFullDisplayBalance(new BigNumber(balance.toString()), decimals, displayDecimals), displayDecimals)
}

// 格式化数值，用千分位表示，小数点后几位自己设置
export const formatNumber = (num: any, decimals: number) => {
  let number = formatNumberPoint(num, decimals)
  return number && number.toString()
  .replace(/\d+/, function(s){
       return s.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
   })
}

// 截取小数点后几位
export const formatNumberPoint = (num: any, decimals: number) => {
  let isType = (typeof num)
  if (!num || (isType === 'object' || isType === 'function') ) {
    return 0
  }

  let changeNum = num.toString()
  let index = changeNum.indexOf('.')
  if (index === -1) {
    return num
  } else {
    let result = changeNum.slice(0, index + decimals + 1)
    return result // 清除小数点后多余的0
  }
}

/**
 * Method to format the display of wei given an ethers.BigNumber object
 * Note: does NOT round
 */
export const formatBigNumber = (number: ethers.BigNumber, displayDecimals = 18, decimals = 18) => {
  const remainder = number.mod(ethers.BigNumber.from(10).pow(decimals - displayDecimals))
  return formatUnits(number.sub(remainder), decimals)
}

/**
 * Method to format the display of wei given an ethers.BigNumber object with toFixed
 * Note: rounds
 */
export const formatBigNumberToFixed = (number: ethers.BigNumber, displayDecimals = 18, decimals = 18) => {
  const formattedString = formatUnits(number, decimals)
  return (+formattedString).toFixed(displayDecimals)
}

/**
 * Formats a FixedNumber like BigNumber
 * i.e. Formats 9763410526137450427.1196 into 9.763 (3 display decimals)
 */
export const formatFixedNumber = (number: ethers.FixedNumber, displayDecimals = 18, decimals = 18) => {
  // Remove decimal
  const [leftSide] = number.toString().split('.')
  return formatBigNumber(ethers.BigNumber.from(leftSide), displayDecimals, decimals)
}
