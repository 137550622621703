import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line
import { PancakeTheme } from '@moma-nft/momanft-ui/dist/theme'

declare module 'styled-components' {
  // eslint-disable-next-line
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
