import React, { useState } from 'react'
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
} from '@moma-nft/momanft-ui'
import { parseUnits } from 'ethers/lib/utils'
import styled from 'styled-components'
import { useGetBnbBalance } from '../../../hooks/useTokenBalance'
import { FetchStatus } from '../../../config/constants/types'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'
// import WalletNFTS from './WalletNFTS'

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
  // NFTS
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_BNB_BALANCE = parseUnits('2', 'gwei')

const ModalHeader = styled(UIKitModalHeader)`
  /* background: ${({ theme }) => theme.colors.gradients.bubblegum}; */
`

const Tabs = styled.div`
  padding: 10px 16px;
`

const CloseBox = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

const BodyBox = styled(ModalBody)`
  box-sizing: border-box;
  padding: 0 16px 20px;
`

const WalletModal: React.FC<WalletModalProps> = ({ initialView = WalletView.WALLET_INFO, onDismiss }) => {
  const [view, setView] = useState(initialView)
  const { balance, fetchStatus } = useGetBnbBalance()
  const hasLowBnbBalance = fetchStatus === FetchStatus.Fetched && balance.lte(LOW_BNB_BALANCE)

  const handleClick = (newIndex: number) => {
    setView(newIndex)
  }

  return (
    <ModalContainer minWidth="340px">
      <ModalHeader>
        <ModalTitle>
          <Heading>Your Wallet</Heading>
        </ModalTitle>
        <CloseBox variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </CloseBox>
      </ModalHeader>
      <Tabs>
        <ButtonMenu scale="sm" variant="primary" onItemClick={handleClick} activeIndex={view} fullWidth>
          <ButtonMenuItem>Wallet</ButtonMenuItem>
          <ButtonMenuItem>Transactions</ButtonMenuItem>
          {/* <ButtonMenuItem>NFT</ButtonMenuItem> */}
        </ButtonMenu>
      </Tabs>
      <BodyBox maxWidth="340px" width="100%">
        {view === WalletView.WALLET_INFO && <WalletInfo hasLowBnbBalance={hasLowBnbBalance} onDismiss={onDismiss} />}
        {view === WalletView.TRANSACTIONS && <WalletTransactions />}
        {/* {view === WalletView.NFTS && <WalletNFTS />} */}
      </BodyBox>
    </ModalContainer>
  )
}

export default WalletModal
