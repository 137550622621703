import React from 'react'
import { light, dark, ModalProvider } from '@moma-nft/momanft-ui'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { Web3ReactProvider } from '@web3-react/core'
import { LanguageProvider } from 'contexts/Localization'
import { getLibrary } from './utils/web3React'
import store from './state'
import { ToastsProvider } from './contexts/ToastsContext'

// import { useThemeManager } from 'state/user/hooks'

const ThemeProviderWrapper = (props:any) => {
  // const [isDark] = useThemeManager()
  const isDark = false
  return <ThemeProvider theme={isDark ? dark : light} {...props} />
}

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
       <Provider store={store}>
          <ToastsProvider>
            <ThemeProviderWrapper>
              <LanguageProvider>
                <ModalProvider>
                  {children}
                </ModalProvider>
              </LanguageProvider>
            </ThemeProviderWrapper>
          </ToastsProvider>
       </Provider>
    </Web3ReactProvider>
  )
}

export default Providers
