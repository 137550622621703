import { sample } from "lodash"

if (process.env.NODE_ENV !== 'production' && !process.env.REACT_APP_NODE) {
  throw Error('One base RPC URL is undefined')
}

export const nodes = [process.env.REACT_APP_NODE]

const getNodeUrl = () => {
  if (process.env.REACT_APP_NODE_PRODUCTION) {
    return process.env.REACT_APP_NODE_PRODUCTION
  }
  return sample(nodes)
}

export default getNodeUrl
