import React from 'react'
import styled from 'styled-components'

const Footer: React.FC = () => {
  return (
    <FooterBox>
      <FooterMain>footer</FooterMain>
    </FooterBox>
  )
}

const FooterBox = styled.div`
  width: 100%;
  height: 100px;
  // margin-top: 150px;
  background-color: #0D0E12;
`

const FooterMain = styled.div`
  width: 1040px;
  height: 100px;
  margin: 0 auto;
  font-size: 20px;
  color: #fff;
`

export default Footer;