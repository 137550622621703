import React from 'react'
import { Link, Text } from '@moma-nft/momanft-ui'
import truncateHash from '../../utils/truncateHash'
import { getEtherScanLink } from '../../utils'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
}

const DescriptionWithTx: React.FC<DescriptionWithTxProps> = ({ txHash, children }) => {
  return (
    <>
      {typeof children === 'string' ? <Text as="p">{children}</Text> : children}
      {txHash && (
        <Link external href={getEtherScanLink(txHash, 'transaction')}>
          {'View on EtherScan'}: {truncateHash(txHash, 8, 0)}
        </Link>
      )}
    </>
  )
}

export default DescriptionWithTx
