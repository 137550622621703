import BigNumber from 'bignumber.js'
import { BIG_TEN } from 'utils/bigNumber'

export enum ChainId {
  MAINNET = 1,
  TESTNET = 3
}

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const ETHER_BLOCK_TIME = 3

export const BASE_ETHER_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://etherscan.io',
  [ChainId.TESTNET]: 'https://ropsten.etherscan.io',
}

export const BLOCKS_PER_YEAR = (60 / ETHER_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const BASE_ETHER_SCAN_URL = BASE_ETHER_SCAN_URLS[ChainId.TESTNET]
export const BASE_URL = ''
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 2000000
// In reality its 10000 because of fast refresh, a bit less here to cover for possible long request times
