import { MenuItemsType } from '@moma-nft/momanft-ui'
// import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

// const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
const config: () => ConfigMenuItemsType[] = () => [
  {
    label: 'Dashboard',
    icon: 'Dashboard',
    href: '/dashboard',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: 'Markets',
    icon: 'Home',
    href: '/markets',
    showItemsOnMobile: false,
    items: [],
  }
]

export default config
