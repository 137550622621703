export default {
  MQuery: {
    1: '',
    3: '0x7Ba6c470CD08Ec94AC7902eFBAB2cf9B0Ca5DB11'
  },
  BorrowerMaster: {
    1: '',
    3: '0xCbd2eAe05Cc82Ad407DFe31e8d4a97e254AF1749'
  },
  LenderMasterEther: {
    1: '',
    3: '0x3a71e9A4d27BEA1B6Dd4A2263C3415ac9351213B'
  }
}
